<template>
  <v-list
    dense
    class="rounded-b-xl"
    v-if="results"
    >
    <v-subheader
      class="px-4"
      >Productos</v-subheader>
    <v-list-item-group
      color="primary"
      three-line
      >
      <template
        v-for="(result, i) in results"
        >
        <product
          :result="result"
          ></product>
      </template>

      <v-list-item
        v-if="!results.length"
        disabled
        >
        <v-list-item-content
          class="text-center"
          >
          <v-list-item-title>Lo sentimos, no se encontraron resultados.</v-list-item-title>
          <v-list-item-subtitle>Intente nuevamente con otros términos.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import { SearchProducts } from '@/graphql/queries/products'

const Product = () => import('./Product')

export default {
  data () {
    return {
      results: null
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    search (search) {
      if (search.length > 2) {
        this.$apollo.query({
          query: SearchProducts,
          variables: {
            search: search,
            limit: 5
          }
        }).then ( res => {
          this.results = res.data.searchProducts
        })
      } else if (!search.length) {
        this.results = null
      }
    }
  },

  components: { Product }
}
</script>
