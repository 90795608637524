import gql from 'graphql-tag'

export const User = gql`
  query findUser($dni: String!) {
    findUser(dni: $dni) {
      token 
      user {
        id
        profile {
          fullname
          idType
          idNumber
          address
          phone
          email
        }
      }
    }
  }
`

export const Users = gql`
  query users($search: String) {
    users(search: $search) {
      id
      profile {
        fullname
        idType
        idNumber
        address
        phone
        email
      }
    }
  }
`

export const SearchUsers = gql`
  query searchUsers($search: String!, $limit: ID) {
    searchUsers(search: $search, limit: $limit) {
      id
      name
      profile {
        slug
        picture {
          thumbUrl
        }
      }
    }
  }
`
