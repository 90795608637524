<template>
  <div 
    class="position: relative"
    :style="appbar ? '' : 'margin-top: -1px'" :class="appbar ? '' : 'mb-3'">
    <v-card
      :class="focused ? 'rounded-b-0' : 'rounded-b-lg'"
      :style="
      !focused
      ? 'transition: border-radius 0.3s ease-out; transition-delay: 0.35s'
      : ''
      "
      class="rounded-lg"
      style="position: relative"
      flat
      >
      <div 
        @focus="focused = true"
        @focusout="loseFocus"
        tabindex="0"
        :class="appbar ? '' : 'pa-4'">
        <v-text-field
          @focus="focused = true"
          solo
          dense
          prepend-inner-icon="mdi-magnify"
          color="primary"
          v-model.trim="search"
          :label="$t('actions.search')"
          clearable
          class="rounded-xl"
          outlined
          flat
          hide-details
          ></v-text-field>
      </div>

      <v-expand-transition>
        <v-card
          style="z-index: 300; width: 376px; margin-top: 4px; border-top-width: 0"
          :style="$vuetify.breakpoint.mdAndUp ? 'width: 376px; position: fixed' : 'width: calc(100%); position: absolute'"
          v-show="focused"
          v-if="search"
          class="rounded-b-xl rounded-t-0"
          >
          <v-card-text 
            class="pa-0">
            <products ref="products"></products>

            <stores ref="stores"></stores>

            <users ref="users"></users>
          </v-card-text>

          <div
            class="text-center py-2"
            style="cursor: pointer"
            @focus="focused = false"
            >
            <v-icon>
              mdi-chevron-up
            </v-icon>
          </div>
        </v-card>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import Products from "@/components/search/Products";
import Stores from "@/components/search/Stores";
import Users from "@/components/search/Users";

export default {
  name: "Search",
  data() {
    return {
      loading: false,
      search: "",
      focused: false,
      timer: null,
    };
  },
  props: {
    appbar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Products,
    Stores,
    Users,
  },

  computed: {
    mainHeight() {
      return document.getElementsByClassName("v-main__wrap")[0].offsetHeight;
    },
  },

  watch: {
    search() {
      if (this.search) {
        this.focused = true;
        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.perform();
        }, 200);
      } else {
        this.focused = false;
        this.perform();
      }
    },

    focused () {
      this.$forceUpdate()
    }
  },

  methods: {
    perform() {
      if (this.focused) {
        this.$refs.products.search(this.search);
        this.$refs.stores.search(this.search);
        this.$refs.users.search(this.search);
      }
    },

    loseFocus() {
      setTimeout( () => {
        this.focused = false;
      }, 100)
    },
  },
};
</script>
