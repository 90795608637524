<template>
  <v-list
    dense
    class="rounded-b-xl"
    v-if="results"
    >
    <v-subheader
      class="px-4"
      >Partners</v-subheader>
    <v-list-item-group
      color="primary"
      three-line
      >
      <v-list-item
        v-for="(result, i) in results"
        :key="i"
        :to="{ name: 'profile', params: { slug: result.profile.slug, search: true } }"
        >
        <v-list-item-avatar
          rounded="lg"
          size="50"
          >
          <v-img
            v-if="result.profile.picture"
            :src="url + result.profile.picture.thumbUrl"
            ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="result.name"></v-list-item-title>
          <v-list-item-subtitle v-text="result.description"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="result.information.fullAddress"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="!results.length"
        disabled
        >
        <v-list-item-content
          class="text-center"
          >
          <v-list-item-title>Lo sentimos, no se encontraron resultados.</v-list-item-title>
          <v-list-item-subtitle>Intente nuevamente con otros términos.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import { SearchStores } from '@/graphql/queries/stores'

export default {
  data () {
    return {
      results: null
    }
  },

  computed: {
    ...mapGetters(['url', 'currentUser'])
  },

  methods: {
    search (search) {
      if (search.length > 2) {
        this.$apollo.query({
          query: SearchStores,
          variables: {
            search: search,
            limit: 5
          }
        }).then ( res => {
          this.results = res.data.searchStores
        })
      } else if (!search.length) {
        this.results = null
      }
    }
  }
}
</script>
